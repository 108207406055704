import { useState } from "react";
import { Alert, Button, Input, InputGroup, InputGroupText } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import { PARANET_SERVICE_HOST, getParanetApolloClient } from ".";
import { gql } from "@apollo/client";

export default function Login({ setActorContext }: any) {
    const [actorId, setActorId] = useState("");
    const [password, setPassword] = useState("");
    const [failed, setFailed] = useState("");

    const handleLogin = async (ev: any) => {
        ev.preventDefault();
        console.log("LOGIN");
        try {
            const response = await fetch(`${PARANET_SERVICE_HOST}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id: actorId,
                    password: password,
                }),
            });
            console.log("RESPONSE", response);


            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error);
            }

            const token = data.access_token;
            const refresh = data.refresh_token;

            // Extract claims from JWT
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function (c) {
                        return (
                            "%" +
                            ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join("")
            );

            let actor = JSON.parse(jsonPayload).actor;
            console.log("SET ACTOR CONTEXT", actor);
            setActorContext({
                versions: [],
                token: token,
                refresh: refresh,
                name: actor,
            });
            let apClient = getParanetApolloClient();
            let versionData = await apClient.query<{
                listActors: { actors: { entityId: string }[] };
            }>({
                query: gql`
                    query ListActors($id: Id) {
                        listActors(id: $id) {
                            actors {
                                entityId
                            }
                        }
                    }
                `,
                variables: { id: actor },
            });
            let versions = versionData.data.listActors.actors.map(
                ({ entityId }) => entityId
            );
            setActorContext({
                versions,
                selectedVersion: 0,
                token,
                refresh,
                name: actor,
            });
        } catch (error) {
            console.error("Login failed:", error);
            // @ts-ignore
            setFailed(error.message);
        }
    };

    return (
        <div>
            <form onSubmit={handleLogin}>
                <InputGroup>
                    <InputGroupText>@</InputGroupText>
                    <Input
                        placeholder="Actor Id"
                        onChange={(ev) => setActorId(ev.target.value)}
                    />
                    <Input
                        placeholder="Password"
                        type="password"
                        onChange={(ev) => setPassword(ev.target.value)}
                    />
                    <Button type="submit" color="primary">
                        Login
                    </Button>
                </InputGroup>
            </form>
            <Alert
                color="danger"
                isOpen={!!failed}
                toggle={() => setFailed("")}
            >
                Login failed: <b>{failed}</b>.
            </Alert>
        </div>
    );
}
