// import "./styles.css";
import { Link, useParams } from "react-router-dom";
import { gql } from "@apollo/client";
import DataGrid, { Column, RowsChangeData } from "react-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { Sheet, Card, List, Divider, Chip, Box } from "@mui/joy";
import {
    MessageBody,
    PncpMessage,
    RequestMessage,
} from "./types/paranet_types";
import { JsonForms } from "@jsonforms/react";
import {
    materialCells,
    materialRenderers,
} from "@jsonforms/material-renderers";
import KvTable from "./KVTable";
import { Graph } from "./graph/Graph";
import DataRender from "./DataRender";
import { getParanetApolloClient } from ".";
import { CellExpanderFormatter } from "./CellExpanderFormatter";
import { EmptyRowsRenderer, isAtBottom } from "./util";

const GET_DETAIL = gql`
    query GetConversationDetails($conversation: String) {
        conversationById(id: $conversation) {
            id
            state
            parentId
            subject
            action
            initiator {
                actorDetails {
                    entityId
                    metadata {
                        name
                    }
                    modelKind
                }
            }
            recipient {
                actorDetails {
                    entityId
                    metadata {
                        name
                    }
                    modelKind
                }
            }
            createdAt
            children {
                conversations {
                    id
                }
            }
            observers {
                targetActor {
                    entityId
                    metadata {
                        name
                    }
                    modelKind
                }
                messageId
                obsId
            }
            messages {
                id
                createdAt
                requestId
                senderEntityId
                contents
            }
        }
    }
`;

export type Conversation = {
    id: string;
    state: string; // TODO: We could expand this.
    parentId: string | null;
    childrenIds: string[];
    initiator: ActorDetails;
    recipient?: ActorDetails;
    createdAt: Date;
    subject: string | null;
    action: string | null;
    observers: {
        targetActor: ActorDetails;
        messageId: string;
        obsId: string;
    }[];
    messages: Message[];
};

export type ActorDetails = {
    entityId: string;
    metadata: { name: string };
    modelKind: string;
};

export type Message = {
    id: string;
    createdAt: Date;
    senderEntityId: string;
    requestId: string | null;
    contents: MessageBody;
};

export async function loadConvData(
    conversation: string
): Promise<Conversation> {
    console.log("loadConvData:", conversation);
    let result = await getParanetApolloClient().query({
        query: GET_DETAIL,
        variables: {
            conversation,
        },
    });

    // Clean up the data and then return it.
    let data = Object.assign({}, result.data.conversationById);
    data.initiator = data.initiator.actorDetails;
    data.recipient = data.recipient?.actorDetails;
    data.createdAt = new Date(data.createdAt);
    data.childrenIds = data.children.conversations.map((c: any) => c.id);

    data.messages = data.messages.map((m: any) => {
        let newM = Object.assign({}, m);
        newM.createAt = new Date(m.createdAt);
        return newM;
    });

    return data;
}

const GET_MESSAGES = gql`
    query ListConversationMessages(
        $conversation: String
        $token: String
        $limit: Int
    ) {
        listConversationMessages(
            id: $conversation
            limit: $limit
            token: $token
        ) {
            messages {
                id
                createdAt
                senderEntityId
                contents
            }
            lastToken
        }
    }
`;

type Row =
    | {
          row: "MAIN";
          id: string;
          time: number;
          sender: string;
          type: string;
          expanded: boolean;
          contents: any;
      }
    | {
          row: "DETAIL";
          id: string;
          content: any;
      };

async function loadMoreRows(
    conversation: string,
    limit: number,
    lastToken: String | null
): Promise<[Row[], String]> {
    let data = await getParanetApolloClient().query({
        query: GET_MESSAGES,
        variables: {
            token: lastToken,
            limit: limit,
            conversation: conversation,
        },
    });
    let messages = data.data.listConversationMessages.messages;
    let token = data.data.listConversationMessages.lastToken;

    // @ts-ignore
    const rows = messages.map(({ id, createdAt, senderEntityId, contents }) => ({
        row: "MAIN",
        id: id,
        time: new Date(createdAt),
        sender: senderEntityId,
        type: contents.type,
        contents: contents,
    }));

    return [rows, token];
}

export default function ConversationDetail() {
    const { conversation } = useParams<{
        conversation: string;
    }>();

    const columns: readonly Column<Row>[] =
        useMemo((): readonly Column<Row>[] => {
            console.log("RECOMPUTING!", conversation);
            return [
                {
                    key: "expanded",
                    name: "",
                    minWidth: 30,
                    width: 30,
                    colSpan(args) {
                        return args.type === "ROW" && args.row.row === "DETAIL"
                            ? 5
                            : undefined;
                    },
                    cellClass(row) {
                        return row.row === "DETAIL" ? "expanded" : undefined;
                    },
                    formatter({ row, isCellSelected, onRowChange }) {
                        if (row.row === "DETAIL") {
                            return <RenderMessage msg={row.content} />;
                        }
                        return (
                            <CellExpanderFormatter
                                isCellSelected={isCellSelected}
                                expanded={row.expanded}
                                onCellExpand={() => {
                                    onRowChange({
                                        ...row,
                                        expanded: !row.expanded,
                                    });
                                }}
                            />
                        );
                    },
                },
                { key: "id", name: "Message" },
                {
                    key: "time",
                    name: "Time",
                    formatter({ isCellSelected, column, row, onRowChange }) {
                        if (row.row === "MAIN") {
                            return row.time.toLocaleString();
                        }
                    },
                },
                { key: "sender", name: "Sender" },
                { key: "type", name: "Type" },
            ];
        }, [conversation]);

    const [rows, setRows] = useState([] as Row[]);
    const [token, setToken] = useState(null as String | null);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [convData, setConvData] = useState<Conversation | null>(null);

    function onRowsChange(rows: Row[], { indexes }: RowsChangeData<Row>) {
        const row = rows[indexes[0]];
        if (row.row === "MAIN") {
            if (!row.expanded) {
                rows.splice(indexes[0] + 1, 1);
            } else {
                rows.splice(indexes[0] + 1, 0, {
                    row: "DETAIL",
                    id: row.id + "expanded",
                    content: row.contents,
                });
            }
            setRows(rows);
        }
    }

    async function addRows(limit: number, reset: boolean = false) {
        if (conversation) {
            setIsLoading(true);

            console.log("ADDROWS:", conversation);

            const [newRows, lastToken] = await loadMoreRows(
                conversation,
                limit,
                reset ? null : token
            );
            // @ts-ignore
            setToken(lastToken);

            if (newRows.length < limit) {
                setIsComplete(true);
            }

            if (reset) {
                setRows(newRows);
            } else {
                setRows([...rows, ...newRows]);
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await addRows(20, true);
        })();
    }, [conversation]);

    useEffect(() => {
        (async () => {
            let conv = await loadConvData(conversation!);
            setConvData(conv);
        })();
    }, [conversation]);

    async function handleScroll(event: React.UIEvent<HTMLDivElement>) {
        if (isComplete || isLoading || !isAtBottom(event)) return;
        await addRows(10);
    }

    return (
        <Sheet>
            <Card>{convData ? <ConversationCtx data={convData} /> : null}</Card>
            <Divider>
                <Chip>Messages</Chip>
            </Divider>
            <Box>
                <DataGrid
                    rowKeyGetter={rowKeyGetter}
                    className="rdg-light"
                    columns={columns}
                    rows={rows}
                    rowHeight={(args) =>
                        args.type === "ROW" && args.row.row === "DETAIL"
                            ? 300
                            : 35
                    }
                    renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
                    onScroll={handleScroll}
                    onRowsChange={onRowsChange}
                    defaultColumnOptions={{
                        resizable: true,
                    }}
                />
            </Box>
            <Divider>
                <Chip>Conversation Tree View</Chip>
            </Divider>
            <Card sx={{ width: "95vw", height: "95vh", left: "2.5vw" }}>
                {convData && <Graph root={convData} />}
            </Card>
        </Sheet>
    );
}

export function ConversationCtx({ data }: { data: Conversation }) {
    return (
        <KvTable
            headerWidth="10%"
            data={[
                { key: "ID", value: data.id },
                {
                    key: "Time Created",
                    value: data.createdAt.toUTCString(),
                },
                { key: "State", value: data.state },
                {
                    key: "Parent ID",
                    value: data.parentId ? (
                        <Link to={`/conversation/${data.parentId}`}>
                            {data.parentId}
                        </Link>
                    ) : (
                        "(none)"
                    ),
                },
                { key: "Subject", value: data.subject },
                { key: "Action", value: data.action },
                {
                    key: "Initiator",
                    value: (
                        <Link to={`/actor/${data.initiator.entityId}`}>
                            {data.initiator.entityId}
                        </Link>
                    ),
                },
                {
                    key: "Recipient",
                    value: data.recipient ? (
                        <Link to={`/actor/${data.recipient.entityId}`}>
                            {data.recipient.entityId}
                        </Link>
                    ) : (
                        <p>(No recipient)</p>
                    ),
                },
                {
                    key: "Children",
                    value: (
                        <List>
                            {data.childrenIds.map((id) => (
                                <Link to={`/conversation/${id}`}>{id}</Link>
                            ))}
                        </List>
                    ),
                },
            ]}
        />
    );
}

function RenderMessage({ msg }: { msg: MessageBody }) {
    switch (msg.type) {
        case "SkillRequest":
            return RenderSkillMessage(msg.data);
        case "PncpMessage":
            return RenderPncpMessage(msg.data);
    }
}

function RenderSkillMessage(msg: RequestMessage) {
    return (
        <Card>
            <KvTable
                headerWidth="10%"
                data={[
                    { key: "Match Strategy", value: msg.request.matchStrategy },
                    {
                        key: "Data",
                        value: <DataRender data={msg.data?.data} />,
                    },
                    {
                        key: "Provider Data",
                        value: <DataRender data={msg.data?.provider_data} />,
                    },
                    {
                        key: "Skill Provider Data",
                        value: <DataRender data={msg.data?.skill_data} />,
                    },
                    {
                        key: "Callback Data",
                        value: (
                            <DataRender
                                data={
                                    msg.request.callback
                                        ? msg.request.callback
                                        : {}
                                }
                            />
                        ),
                    },
                ]}
            />
        </Card>
    );
}

function RenderPncpMessage(msg: PncpMessage) {
    return (
        <Card>
            <KvTable
                headerWidth="10%"
                data={[
                    { key: "Type", value: msg.message.message_type },
                    {
                        key: "Data",
                        value: <DataRender data={msg.message.data} />,
                    },
                ]}
            />
        </Card>
    );
}

function rowKeyGetter(row: Row) {
    return row.id;
}
