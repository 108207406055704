import { gql, useQuery } from "@apollo/client";
import DataGrid, { Column } from "react-data-grid";
import { ActorLink } from "./Links";
import { EmptyRowsRenderer } from "./util";

export const GET_ACTORS = gql`
    query {
        listBaseActors {
            id
            name
        }
    }
`;

interface Row {
    id: string;
    name: string;
}

const columns: readonly Column<Row>[] = [
    {
        key: "id",
        name: "Id",
        formatter({ isCellSelected, column, row, onRowChange }) {
            return <ActorLink id={row.id} />;
        },
    },
    { key: "name", name: "Name" },
];

interface GetRows {
    listBaseActors: Row[];
}

export default function Actors() {
    const { loading, error, data } = useQuery<GetRows>(GET_ACTORS, {
        pollInterval: 5000,
    });

    if (loading) return <div>'Loading...'</div>;
    if (error) return <div>`Error! ${error.message}`</div>;
    if (!data) return <div>'Empty'</div>;

    return (
        <DataGrid
            className="rdg-light rdg-main"
            columns={columns}
            rows={data.listBaseActors}
            renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
            defaultColumnOptions={{
                resizable: true,
            }}
            // style={{ alignItems: 'center'}}
        />
    );
}
