import React, {useState} from "react";
// @ts-ignore
import DateTimePicker from 'react-datetime-picker';

export interface DateRange { start: Date | null, end: Date | null };
interface Filter {
    value: DateRange
    onChange(range: DateRange): void,
};


export default function FilterDateRange(props: Filter) {
    return (<div className="dateRange">
        <DateTimePicker
            onPaste={(e: any) => {
                const data = e.clipboardData.getData('Text');
                const date = new Date(data);
                if (!isNaN(date.getTime())) {
                    props.onChange({start: date, end: props.value.end});
                }
                e.preventDefault();
                e.stopPropagation();
            }}
            disableCalendar={false}
            disableClock={true}
            onChange={(date: any) => {
                props.onChange({start: date, end: props.value.end});
            }}
            value={props.value.start}
        />
        <DateTimePicker onPaste={(e: any) => {
            const data = e.clipboardData.getData('Text');
            const date = new Date(data);
            if (!isNaN(date.getTime())) {
                props.onChange({start: props.value.start, end: date});
            }
            e.preventDefault();
            e.stopPropagation();
        }} disableCalendar={false} disableClock={true} onChange={(date: any) => {
            props.onChange({start: props.value.start, end: date});
        }} value={props.value.end}/>
    </div>)
}