import { GenericObjectType, RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import React, { FormEvent, useState } from "react";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Form as Autoform } from "@rjsf/bootstrap-4";
import { IChangeEvent } from "@rjsf/core";
import useActorContext from "./useActorContext";
import { isPbAvailable, sendPbMsg } from "./parabrowserConnection";
import { Link } from "react-router-dom";
import { SkillRequest } from "paranet-client";
import { getParanetClient } from ".";

async function sendRequest(
    actorId: string,
    subject: string,
    action: string,
    data: any
): Promise<SkillRequestResponse | null> {
    // Check if we can send this via the parabrowser instead.

    if (isPbAvailable()) {
        await sendPbMsg({
            type: "skill",
            subject,
            action,
            data,
        });
        return null;
    } else {
        const req: SkillRequest = {
            actorVersion: actorId.split("@")[1],
            action,
            subject,
            data,
            authorKind: "paraview",
        };

        const client = getParanetClient();
        let response = await client.skillRequest(req);
        return { id: response.id };
    }
}

export type SkillRequestResponse = {
    id: string;
};

export type SkillDialog = {
    subject: string;
    action: string;
    schema?: GenericObjectType;
};

interface Props {
    shown: boolean;
    onHide: () => void;
    skill: SkillDialog;
}

const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
        submitText: "Send",
    },
};

export const SkillRequestForm: React.FC<Props> = ({
    shown,
    onHide,
    skill,
}: Props) => {
    const { actorContext } = useActorContext();
    const [actorRequestFormData, setActorRequestFormData] = useState<any>(null);
    const [dialogSuccess, setDialogSuccess] = useState("");
    const [dialogError, setDialogError] = useState("");

    const onChangeRequestData = (data: IChangeEvent, id?: string) => {
        const { formData } = data;
        setActorRequestFormData(formData);
    };

    const onSubmitRequest = (data: IChangeEvent, e: FormEvent) => {
        const { formData } = data;
        if (skill) {
            setDialogError("Sending ...");
            const { subject, action } = skill;
            sendRequest(
                actorContext!.versions[actorContext!.selectedVersion],
                subject,
                action,
                formData
            )
                .then((response) => {
                    setDialogError("");
                    if (response) {
                        // Split the ID.
                        let text = response.id.split("@")[0];
                        setDialogSuccess(text);
                    } else {
                        onHide();
                    }
                })
                .catch((err) => {
                    setDialogError(err.message);
                });
        }
    };

    const toggle = () => {
        if (shown) onHide();
    };

    console.log(skill.schema);
    return (
        <Modal size="xl" isOpen={shown} onClosed={onHide} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                New Request: {skill.subject}/{skill.action}
            </ModalHeader>
            <ModalBody>
                <Container fluid>
                    <Row>
                        <Col>
                            {skill.schema && (
                                <Autoform
                                    schema={skill.schema}
                                    uiSchema={uiSchema}
                                    validator={validator}
                                    formData={actorRequestFormData}
                                    onChange={onChangeRequestData}
                                    onSubmit={onSubmitRequest}
                                ></Autoform>
                            )}
                        </Col>
                    </Row>
                    {dialogSuccess && (
                        <Row className="py-2">
                            <Col className="dialog-success">
                                Conversation created:{" "}
                                <Link to={`/conversation/${dialogSuccess}`}>
                                    {dialogSuccess}
                                </Link>
                            </Col>
                        </Row>
                    )}
                    {dialogError && (
                        <Row className="py-2">
                            <Col className="dialog-error">{dialogError}</Col>
                        </Row>
                    )}
                </Container>
            </ModalBody>
        </Modal>
    );
};
