import { CardSubtitle, ListGroup, ListGroupItem } from "reactstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    SkillsetActions,
    SkillsetData,
    SkillsetModel,
    SkillsetSubject,
} from "./Skillset";
import { SkillDialog, SkillRequestForm } from "./SkillRequestForm";

interface Props {
    skillsets: SkillsetData[];
}

export default function ActorSkillsets({ skillsets }: Props) {
    const [showActorModal, setShowActorModal] = useState(false);
    const [actorDialog, setActorDialog] = useState<SkillDialog | null>(null);

    const openRequest = (subj: SkillsetSubject, actionIdx: number) => {
        console.log(subj, actionIdx);
        const action = subj.actions[actionIdx];
        setActorDialog({
            subject: subj.subject,
            action: action.action,
            schema: action.inputSchema,
        });
        setShowActorModal(true);
    };

    return (
        <div>
            <ListGroup>
                <h6 className="mt-3">Skillsets</h6>
                {skillsets.map((model, index) => (
                    <ListGroupItem key={index}>
                        {model.baseSkill ? (
                            <div> Base skill: {model.id}</div>
                        ) : (
                            <Link
                                to={`/skillset/${model.id}`}
                                state={{ skillset: model }}
                            >
                                {model.id}
                            </Link>
                        )}
                        <SkillsetActions
                            skills={model.skills}
                            request_dialog={openRequest}
                        />
                    </ListGroupItem>
                ))}
            </ListGroup>
            {actorDialog && (
                <SkillRequestForm
                    shown={showActorModal}
                    onHide={() => setShowActorModal(false)}
                    skill={actorDialog}
                ></SkillRequestForm>
            )}
        </div>
    );
}

