import React from "react";

export function EmptyRowsRenderer() {
    return (
        <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
            Empty
        </div>
    );
}

export function isAtBottom({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean {
    return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;
}

