import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { ActorContext } from "./useActorContext";

interface Props {
    context: ActorContext | null;
    onLogout: () => void;
}

export default function Header({ context, onLogout }: Props) {
    return (
        <Navbar color="light" light expand="md">
            <NavbarBrand tag={Link} to="/">
                Paraview
            </NavbarBrand>
            <Nav className="me-auto" navbar>
                <NavItem>
                    <NavLink href="/actors/">Actors</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/conversations/">Conversations</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/skillsets/">Skillsets</NavLink>
                </NavItem>
            </Nav>
            {context && (
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink>
                            User: {context.name} (
                            {context.versions[context.selectedVersion]})
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <Button color="primary" onClick={onLogout}>
                            Logout
                        </Button>
                    </NavItem>
                </Nav>
            )}
        </Navbar>
    );
}

