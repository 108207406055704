import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import "./Conversations.scss";
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";

import useActorContext from "./useActorContext";
import Actor from "./Actor";
import Table from "./Table";
import Header from "./Header";
import Skillset from "./Skillset";
import Skillsets from "./SkillSets";
import Paraflow from "./Paraflow";
import { CssBaseline } from "@mui/joy";
import Login from "./Login";
import Conversations from "./Conversations";
import ConversationDetail from "./ConversationDetail";
import Actors from "./Actors";
import ParamakerToken from "./ParamakerToken";
import { clearParanetClients, getParanetApolloClient } from ".";
import { ApolloProvider } from "@apollo/client";

const PrivateOnlyComponent: React.FC<any> = ({
    children,
    context,
    setContext,
}) => {
    return (
        <>
            {context ? (
                // This has to be here because we can only access the client if we are first logged in.
                <ApolloProvider client={getParanetApolloClient()}>
                    {children}
                </ApolloProvider>
            ) : (
                <Login setActorContext={setContext} />
            )}
        </>
    );
};

function App() {
    const { actorContext, setActorContext } = useActorContext();

    console.log("ACTOR CONTEXT", actorContext);

    const onLogout = () => {
        console.log("Setting context to NULL");
        setActorContext(null);
        clearParanetClients();
    };

    // @ts-ignore
    return (
        <main className="mainClass">
            <CssBaseline>
                {/*<div style={{fontSize: "30px", textAlign: "center"}}> Parabrowser {actorContext?.name}</div>*/}
                <Router>
                    <Header context={actorContext} onLogout={onLogout} />
                    <Routes>
                        <Route
                            path="/"
                            element={<Navigate to="/conversations" />}
                        />
                        <Route
                            path="/conversations"
                            element={
                                <PrivateOnlyComponent
                                    context={actorContext}
                                    setContext={setActorContext}
                                >
                                    <Conversations />
                                </PrivateOnlyComponent>
                            }
                        />
                        <Route
                            path="/conversation/:conversation"
                            element={
                                <PrivateOnlyComponent
                                    context={actorContext}
                                    setContext={setActorContext}
                                >
                                    <ConversationDetail />
                                </PrivateOnlyComponent>
                            }
                        />
                        <Route
                            path="/actors"
                            element={
                                <PrivateOnlyComponent
                                    context={actorContext}
                                    setContext={setActorContext}
                                >
                                    <Actors />
                                </PrivateOnlyComponent>
                            }
                        />
                        <Route
                            path="/actor/:id"
                            element={
                                <PrivateOnlyComponent
                                    context={actorContext}
                                    setContext={setActorContext}
                                >
                                    <Actor />
                                </PrivateOnlyComponent>
                            }
                        />
                        <Route
                            path="/actor/:id/table/:name"
                            element={
                                <PrivateOnlyComponent
                                    context={actorContext}
                                    setContext={setActorContext}
                                >
                                    <Table />
                                </PrivateOnlyComponent>
                            }
                        />
                        <Route
                            path="/skillsets"
                            element={
                                <PrivateOnlyComponent
                                    context={actorContext}
                                    setContext={setActorContext}
                                >
                                    <Skillsets />
                                </PrivateOnlyComponent>
                            }
                        />
                        <Route
                            path="/skillset/:id/"
                            element={
                                <PrivateOnlyComponent
                                    context={actorContext}
                                    setContext={setActorContext}
                                >
                                    <Skillset />
                                </PrivateOnlyComponent>
                            }
                        />
                        <Route path="/paraflow/:rid" element={<Paraflow />} />
                        <Route
                            path="/paramaker/token"
                            element={<ParamakerToken />}
                        />
                    </Routes>
                </Router>
            </CssBaseline>
        </main>
    );
}

export default App;
