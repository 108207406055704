import { JsonForms } from "@jsonforms/react";
import { createTheme, ThemeProvider } from "@mui/material";
import {
    materialCells,
    materialRenderers,
} from "@jsonforms/material-renderers";

/**
 * Customize form so each control has more space
 */
const theme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    margin: "0.3em 0",
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    wordWrap: "break-word",
                    whiteSpace: "unset",
                },
                underline: {
                    "&.Mui-disabled": {
                        borderBottom: "none",
                        "&&&:before": {
                            borderBottom: "none",
                        },
                        "&&:after": {
                            borderBottom: "none",
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        display: "none",
                    },
                },
            },
        },
    },
    palette: {
        action: {
            disabled: "black",
        },
        text: {
            disabled: "black",
        },
    },
});

export default function DataRender({ data }: { data: any }) {
    console.log("DATA", data);
    if (!!data) {
        return (
            <ThemeProvider theme={theme}>
                <JsonForms
                    readonly={true}
                    config={{
                        multi: true,
                    }}
                    renderers={materialRenderers}
                    cells={materialCells}
                    data={data}
                />
            </ThemeProvider>
        );
    } else {
        return <p>(No data)</p>;
    }
}
