import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "react-data-grid/lib/styles.css";
import { portInit } from "./parabrowserConnection";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import App from "./App";
import { SchemaDB } from "./Schemas";

import { enableMapSet } from "immer";
import {
    ActorContext,
    getActorContext,
    setActorContext,
} from "./useActorContext";
import { ParanetClient, createParanetApolloLink } from "paranet-client";

export const PARANET_SERVICE_HOST: string =
    window.env?.REACT_APP_PARANET_DATA ||
    process.env?.REACT_APP_PARANET_DATA ||
    "http://localhost:3132";

let paranetClient: ParanetClient | null = null;
export function getParanetClient(): ParanetClient {
    if (paranetClient == null) {
        // Gets the "logged in" paranet client.
        let ctx = getActorContext();
        if (!ctx) {
            throw new Error("Not logged in");
        }
        let cl = new ParanetClient({
            actorId: ctx.name,
            serviceEndpoint: PARANET_SERVICE_HOST,
        });
        cl.setTokens(ctx.token, ctx.refresh);
        cl.setTokenCb((token, refresh) => {
            let newCtx: ActorContext = {
                ...ctx!,
                token,
                refresh,
            };
            setActorContext(newCtx);
        });
        paranetClient = cl;
    }
    return paranetClient;
}

export const schemadb = new SchemaDB();

let paranetService: ApolloClient<any> | null = null;
export function getParanetApolloClient(): ApolloClient<any> {
    const pnLink = createParanetApolloLink(getParanetClient());
    if (paranetService == null) {
        paranetService = new ApolloClient({
            link: pnLink,
            cache: new InMemoryCache(),
        });
    }
    return paranetService;
}

export function clearParanetClients() {
    paranetClient = null;
    paranetService = null;
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

function renderApp() {
    enableMapSet();
    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
}

portInit(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
