import DataGrid, {
    Column,
    HeaderRendererProps,
    useFocusRef,
} from "react-data-grid";
import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { gql } from "@apollo/client";
import { getParanetApolloClient } from ".";
import { EmptyRowsRenderer, isAtBottom } from "./util";
import "./Filter.scss";

import { Button, ButtonGroup, Card, CardBody } from "reactstrap";
import FilterDateRange, { DateRange } from "./FilterDateRange";
import { ActorLink, ConversationLink } from "./Links";

const GET_CONVERSATION = gql`
    query ListConversations($token: String, $limit: Int) {
        listConversations(limit: $limit, token: $token) {
            conversations {
                id
                state
                createdAt
                initiator {
                    actorEntityId
                }
                recipient {
                    actorEntityId
                }
                messages {
                    id
                    createdAt
                    senderEntityId
                    contents
                }
            }
            lastToken
        }
    }
`;

interface Conversation {
    id: string;
    state: string;
    createdAt: number;
    recipient: {
        actorEntityId: string;
    };
    initiator: {
        actorEntityId: string;
    };
}

interface Row {
    conversation: string;
    state: string;
    time: Date;
    requester: string;
    recipient: string;
}

interface Filter {
    range: DateRange;
    requester: string;
    recipient: string;
    enabled: boolean;
}

// Context is needed to read filter values otherwise columns are
// re-created when filters are changed and filter loses focus
const FilterContext = createContext<Filter | undefined>(undefined);

function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
    if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
        event.stopPropagation();
    }
}

function selectStopPropagation(event: React.KeyboardEvent<HTMLSelectElement>) {
    if (
        ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(event.key)
    ) {
        event.stopPropagation();
    }
}

async function loadMoreRows(
    limit: number,
    lastToken: string | null
): Promise<[Row[], String]> {
    let data = await getParanetApolloClient().query({
        query: GET_CONVERSATION,
        variables: { token: lastToken, limit: limit },
    });
    let conversations: Conversation[] =
        data.data.listConversations.conversations;

    let token = data.data.listConversations.lastToken;

    console.log("Using token: ", token);

    const rows: Row[] = conversations.map(
        ({ id, state, createdAt, recipient, initiator }) => ({
            conversation: id,
            state,
            time: new Date(createdAt),
            requester: initiator.actorEntityId,
            recipient: recipient?.actorEntityId,
        })
    );

    return [rows, token];
}

export default function Conversations() {
    const [rows, setRows] = useState([] as Row[]);
    const [token, setToken] = useState(null as string | null);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const [filters, setFilters] = useState<Filter>({
        range: { start: null, end: null },
        requester: "",
        recipient: "",
        enabled: true,
    });

    const requesterOptions = useMemo(() => {
        return Array.from(new Set(rows.map((r) => r.requester))).map((d) => ({
            label: d,
            value: d,
        }));
    }, [rows]);

    const recipientOptions = useMemo(() => {
        return Array.from(new Set(rows.map((r) => r.recipient))).map((d) => ({
            label: d,
            value: d,
        }));
    }, [rows]);

    const columns = useMemo((): readonly Column<Row>[] => {
        return [
            {
                key: "conversation",
                name: "Conversation",
                formatter({ row, isCellSelected, onRowChange }) {
                    return <ConversationLink id={row.conversation} />;
                },
            },
            {
                key: "time",
                name: "Time",
                headerCellClass: "filterContainerStyle",
                headerRenderer: (p) => (
                    <FilterRenderer<Row, unknown, any> {...p}>
                        {({ filters, ...rest }) => (
                            <FilterDateRange
                                value={filters.range}
                                onChange={(range) => {
                                    setFilters({ ...filters, range });
                                }}
                            />
                        )}
                    </FilterRenderer>
                ),
                formatter({ isCellSelected, column, row, onRowChange }) {
                    return row.time.toLocaleString();
                },
            },
            {
                key: "state",
                name: "State",
                formatter({ row, isCellSelected, onRowChange }) {
                    return <p>{row.state}</p>;
                },
            },
            {
                key: "requester",
                name: "Requester",
                headerCellClass: "filterContainerStyle",
                headerRenderer: (p) => (
                    <FilterRenderer<Row, unknown, HTMLInputElement> {...p}>
                        {({ filters, ...rest }) => (
                            <input
                                {...rest}
                                className="filterStyle"
                                value={filters.requester}
                                onChange={(e) => {
                                    setFilters({
                                        ...filters,
                                        requester: e.target.value,
                                    });
                                    console.log("Request filter change");
                                }}
                                onKeyDown={inputStopPropagation}
                                list="requester"
                            />
                        )}
                    </FilterRenderer>
                ),
                formatter({ isCellSelected, column, row, onRowChange }) {
                    return <ActorLink id={row.requester} />;
                },
            },
            {
                key: "recipient",
                name: "Recipient",
                headerCellClass: "filterContainerStyle",
                headerRenderer: (p) => (
                    <FilterRenderer<Row, unknown, HTMLInputElement> {...p}>
                        {({ filters, ...rest }) => (
                            <input
                                {...rest}
                                className="filterStyle"
                                value={filters.recipient}
                                onChange={(e) => {
                                    setFilters({
                                        ...filters,
                                        recipient: e.target.value,
                                    });
                                    console.log("Request filter change");
                                }}
                                onKeyDown={inputStopPropagation}
                                list="recipient"
                            />
                        )}
                    </FilterRenderer>
                ),
                formatter({ isCellSelected, column, row, onRowChange }) {
                    return <ActorLink id={row.recipient} />;
                },
            },
        ];
    }, []);

    async function addRows(limit: number) {
        if (isLoading || isComplete) return;
        setIsLoading(true);

        const [newRows, lastToken] = await loadMoreRows(limit, token);
        // @ts-ignore
        setToken(lastToken);

        if (newRows.length < limit) {
            setIsComplete(true);
        }

        setRows([...rows, ...newRows]);
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            const grid = document.querySelector(".rdg");
            const height = grid?.clientHeight;
            const cells = Math.ceil(height ? height / 35 : 20);
            // if (!isComplete && !isLoading) {
            console.log("Loading...", cells);
            await addRows(cells);
            // }
        })();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", () => {
            console.log("Resize!");
            // forceUpdate();
        });
    }, []);

    async function handleScroll(event: React.UIEvent<HTMLDivElement>) {
        console.log("Scroll", isLoading, !isAtBottom(event));
        if (isLoading || !isAtBottom(event)) return;
        await addRows(20);
    }

    const filteredRows = useMemo(() => {
        return rows.filter((r) => {
            return (
                (filters.requester
                    ? r.requester
                          .toLowerCase()
                          .includes(filters.requester.toLowerCase())
                    : true) &&
                (filters.recipient
                    ? r.recipient
                          .toLowerCase()
                          .includes(filters.recipient.toLowerCase())
                    : true) &&
                (filters.range.start
                    ? r.time.getTime() > filters.range.start.getTime()
                    : true) &&
                (filters.range.end
                    ? r.time.getTime() < filters.range.end.getTime()
                    : true)
            );
        });
    }, [rows, filters]);

    function clearFilters() {
        setFilters({
            range: { start: null, end: null },
            requester: "",
            recipient: "",
            enabled: true,
        });
    }

    function toggleFilters() {
        setFilters((filters) => ({
            ...filters,
            enabled: !filters.enabled,
        }));
    }

    return (
        <div className="rootStyle">
            <FilterContext.Provider value={filters}>
                <Card className="filter-controls mb-3">
                    <CardBody>
                        <div className="d-flex justify-content-end align-items-center mb-1">
                            <h5 className="mb-0 me-2">Filters</h5>
                            <ButtonGroup size="sm ">
                                <Button
                                    color="primary"
                                    outline
                                    onClick={toggleFilters}
                                    active={filters.enabled}
                                >
                                    Toggle
                                </Button>
                                <Button
                                    color="danger"
                                    outline
                                    onClick={clearFilters}
                                >
                                    Clear
                                </Button>
                            </ButtonGroup>
                        </div>
                        <DataGrid
                            className={
                                filters.enabled
                                    ? "rdg-light rdg-main filterGridContainerStyle"
                                    : "rdg-light rdg-main"
                            }
                            headerRowHeight={filters.enabled ? 84 : undefined}
                            columns={columns}
                            rows={filteredRows}
                            renderers={{
                                noRowsFallback: <EmptyRowsRenderer />,
                            }}
                            onScroll={handleScroll}
                            defaultColumnOptions={{
                                resizable: true,
                            }}
                        />
                    </CardBody>
                </Card>

                {isLoading && (
                    <div className="loadingRowsStyle">Loading more rows...</div>
                )}
            </FilterContext.Provider>
            <datalist id="requester">
                {requesterOptions.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </datalist>
            <datalist id="recipient">
                {recipientOptions.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </datalist>
        </div>
    );
}

function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
    isCellSelected,
    column,
    children,
}: HeaderRendererProps<R, SR> & {
    children: (args: {
        ref: React.RefObject<T>;
        tabIndex: number;
        filters: Filter;
    }) => React.ReactElement;
}) {
    const filters = useContext(FilterContext)!;
    const { ref, tabIndex } = useFocusRef<T>(isCellSelected);

    return (
        <>
            <div>{column.name}</div>
            {filters.enabled && (
                <div>{children({ ref, tabIndex, filters })}</div>
            )}
        </>
    );
}
