import { useEffect } from "react";
import { getActorContext } from "./useActorContext";

const ParamakerToken = () => {
    const DOMAIN: string =
        window.env?.REACT_APP_ENV || process.env?.REACT_APP_ENV || "grokit";
    useEffect(() => {
        let context = getActorContext();
        window.postMessage(
            { context },
            `https://${DOMAIN}-paramaker.paranet.ai`
        );
        window.close();
    }, []);

    return <div>Sending token...</div>;
};

export default ParamakerToken;
