import { Channel } from "pb-common/dist/channel";
import {
    PvMessageRequest,
    PvMessageResponse,
} from "pb-common/dist/types/messages";
// TODO: Verify that this doesn't change.
const EXT_ID = "ehfgfafnconedjmjblaefohkhjfdggng";

// Some hackery here to allow the code to run in firefox as well as chrome.
// First, we declare `browser` on the globalThis object to allow typescript to recognize that
// the variable exists. This variable is how firefox communicates with extensions, so,
// next we set `chrome` to window.chrome or window.browser and then use that. They follow (mostly)
// the same API, so this should actually work if we port parabrowser to firefox as well.
declare global {
    interface Window {
        browser: any;
    }
}

let chrome: typeof globalThis.chrome | undefined =
    window.chrome || window.browser;

let channel: Channel<PvMessageRequest, PvMessageResponse> | null = null;

export function portInit(complete: () => void) {
    // Check if we are using chrome.
    if (chrome?.runtime) {
        console.log(chrome);
        let port = chrome.runtime.connect(EXT_ID, {
            name: "paraview",
        });

        channel = new Channel(port, (msg) => {
            console.log("GOT MESSAGE:", msg);
            switch (msg.type) {
                case "ext-state": {
                    let userInfo = msg.state.userInfo!;
                    // TODO: Fix this.
                    console.log("Got user info:", userInfo);
                    localStorage.setItem(
                        "paraview-actorContext",
                        JSON.stringify({ id: userInfo.id, name: userInfo.name })
                    );
                    complete();
                }
            }
        });

        port.onDisconnect.addListener(() => {
            console.log("Port disconnected");
            channel = null;
            complete();
        });

        console.log("Successfully created port:", port);
    } else {
        complete();
    }
}

export async function sendPbMsg(
    msg: PvMessageRequest
): Promise<PvMessageResponse> {
    if (channel) {
        return await channel.aSend(msg);
    } else {
        throw new Error("Channel not supported");
    }
}

export function isPbAvailable(): boolean {
    return channel != null;
}
