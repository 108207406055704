import { RJSFSchema } from "@rjsf/utils";
import { getParanetClient } from ".";

export interface SchemaValue {
    type: "value";
    body: SchemaValueRef;
}

export interface SchemaValueRef {
  name: string;
  optional: boolean;
}

export interface SchemaObject {
    type: "map";
    body: { [key: string]: SchemaValueRef };
}

export type SchemaRef = SchemaObject | SchemaValue;

type InputData = { [key: string]: any };

export class SchemaDB {
    schemas: { [key: string]: RJSFSchema };

    constructor() {
        this.schemas = {};
    }

    async getSchema(ref: string): Promise<RJSFSchema> {
        if (!this.schemas[ref]) {
            console.log("GET Schema", ref);
            await this.fetchSchema(ref);
            console.log(this.schemas[ref]);
        }
        if (this.schemas[ref]) return this.schemas[ref];
        else throw new Error(`Failed to fetch schema ${ref}`);
    }

    async fetchSchema(ref: string) {
        const client = getParanetClient();
        let resp: RJSFSchema = await client.getSchema(ref);
        if (resp.type && resp.type === "object" && resp.properties) {
            for (const key in resp.properties) {
                const fieldSchema = resp.properties[key];
                if (typeof fieldSchema === "object") {
                    if (fieldSchema.$ref) {
                        const refSchema = await this.getSchema(
                            fieldSchema.$ref
                        );
                        resp.properties[key] = this.stripSchema(refSchema);
                    }
                }
            }
        }
        this.schemas[ref] = resp;
    }

    stripSchema(schema: RJSFSchema) {
        return {
            type: schema.type,
            const: schema.const,
            enum: schema.enum,
            properties: schema.properties,
            required: schema.required,
        };
    }

    async schemaProperties(schemaRef?: SchemaRef, defaults?: InputData) {
        var properties: { [key: string]: any } = {};
        var required: string[] = [];
        if (schemaRef) {
            if (schemaRef.type === "value") {
                const s = await this.getSchema(schemaRef.body.name);
                if (s.properties) properties = s.properties;
                if (s.required) required = s.required;
            } else if (schemaRef.type === "map") {
                const body = schemaRef.body;
                for (const key in body) {
                    properties[key] = this.stripSchema(
                        await this.getSchema(body[key].name)
                    );
                    required.push(key);
                    if (defaults && defaults[key])
                        properties[key].default = defaults[key];
                }
            }
        }
        return { properties, required };
    }
}

