// Rendering of a table of key-value inputs.

import { Table, Sheet } from "@mui/joy";

interface Kv {
    key: string;
    value: any;
}

function KvTable({
    data,
    size,
    headerWidth,
}: {
    data: Kv[];
    size?: "sm" | "md" | "lg";
    headerWidth?: string;
}) {
    let hWidth = headerWidth || "40%";
    return (
        <Sheet>
            <Table variant="soft" borderAxis="bothBetween" size={size}>
                <tbody>{data.map((kv) => TableRow(kv, hWidth))}</tbody>
            </Table>
        </Sheet>
    );
}

function TableRow(kv: Kv, width: string) {
    return (
        <tr>
            <th scope="row" style={{ width: width }}>
                {kv.key}
            </th>
            <td>{kv.value}</td>
        </tr>
    );
}

export default KvTable;
