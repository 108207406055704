import { gql, useQuery } from "@apollo/client";
import { ListGroup, ListGroupItem } from "reactstrap";
import { SkillSetLink } from "./Links";

export const GET_SKILLSETS = gql`
    query ListSkillSets {
        listSkillSets {
           entityId 
        }
    }
`;

interface SkillSetId {
  entityId: string;
}

interface GetSkillSetIds {
  listSkillSets: SkillSetId[];
}

export default function Skillsets() {
  const { loading, error, data } = useQuery<GetSkillSetIds>(GET_SKILLSETS, {
    pollInterval: 5000, // This is the polling interval in milliseconds
  });

  if (loading) return <div>'Loading...'</div>;
  if (error) return <div>`Error! ${error.message}`</div>;

  return (
    <ListGroup>
      <h6 className="mt-3">Skillsets</h6>
      {data?.listSkillSets.map((skillset, index) => (
        <ListGroupItem key={index}>
          <SkillSetLink id={skillset.entityId} />
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}

