import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";

interface ParaflowGoalRowMeta {
    created: string;
    updated: string;
    description: string;
}

interface ParaflowGoalRow {
    id: number;
    name: string;
    uid: string;
    rid: string;
    pid: string;
    state: string;
    data: { [key: string]: any };
    version: string;
    meta: ParaflowGoalRowMeta;
}

interface ParaflowEdgeRow {
    id: number;
    rid: string;
    source: string;
    target: string;
}

export default function Paraflow() {
    const { rid } = useParams();

    const [activeTab, setActiveTab] = useState("errors");

    const [goalRowList, setGoalRowList] = useState<ParaflowGoalRow[]>([]);
    const [edgeRowList, setEdgeRowList] = useState<ParaflowEdgeRow[]>([]);

    const [showGoalDetail, setShowGoalDetail] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState<ParaflowGoalRow | null>(
        null
    );

    const openGoalDetail = (goal: ParaflowGoalRow) => {
        setSelectedGoal(goal);
        setShowGoalDetail(true);
    };

    const closeGoalDetail = () => {
        setShowGoalDetail(false);
    };

    let uid: Map<string, ParaflowGoalRow> = new Map();
    const errors: ParaflowGoalRow[] = [];
    const active: ParaflowGoalRow[] = [];
    const planned: ParaflowGoalRow[] = [];
    let dependencies: Map<string, ParaflowGoalRow[]> = new Map();
    let dependents: Map<string, ParaflowGoalRow[]> = new Map();

    goalRowList.forEach((goal) => {
        uid.set(goal.uid, goal);
        dependencies.set(goal.uid, []);
        dependents.set(goal.uid, []);
        if (goal.state === "Active") {
            active.push(goal);
        } else if (goal.state === "Backoff") {
            errors.push(goal);
        } else if (goal.state === "Planned") {
            planned.push(goal);
        }
    });

    edgeRowList.forEach((edge) => {
        const target = uid.get(edge.target);
        const source = uid.get(edge.source);
        if (target && source) {
            dependencies.get(target.uid)?.push(source);
            dependents.get(source.uid)?.push(target);
        }
    });

    // const next = planned.filter((goal) => {
    //     let hasChild = false;
    //     console.log(`CHECKING ${goal.name} ${goal.uid}`);
    //     for (const d of dependencies.get(goal.uid) || []) {
    //         console.log(`D ${d.name} ${d.uid} ${d.state}`);
    //         hasChild = true;
    //         if (d.state === "Planned") {
    //             return false;
    //         }
    //     }
    //     return hasChild;
    // });

    const next = planned;
    const updateGoals = () => {
        fetch(`http://localhost:3030/_debug/goalrows?offset=0&limit=1000`)
            .then((res) => res.json())
            .then((result) => setGoalRowList(result))
            .catch((err) => {
                alert(err.message);
            });

        fetch(`http://localhost:3030/_debug/edgerows`)
            .then((res) => res.json())
            .then((result) => setEdgeRowList(result))
            .catch((err) => {
                alert(err.message);
            });
    };

    useEffect(() => {
        updateGoals();
        const timer = setInterval(updateGoals, 500);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    function tabNav(tab: string, name: string) {
        return (
            <NavItem>
                <NavLink
                    className={activeTab == tab ? "active" : ""}
                    onClick={() => toggle(tab)}
                >
                    {name}
                </NavLink>
            </NavItem>
        );
    }

    return rid ? (
        <div>
            {/*<Nav pills>*/}
            {/*    {tabNav("errors", `Errors ${errors.length}`)}*/}
            {/*    {tabNav("active", `Active ${active.length}`)}*/}
            {/*    {tabNav("next", `Next ${next.length}`)}*/}
            {/*</Nav>*/}
            {/*<TabContent activeTab={activeTab}>*/}
            {/*    <TabPane tabId="errors">*/}
            {/*        <GoalTable openGoalDetail={openGoalDetail} goals={errors} />*/}
            {/*    </TabPane>*/}
            {/*    <TabPane tabId="active">*/}
            {/*        <GoalTable openGoalDetail={openGoalDetail} goals={active} />*/}
            {/*    </TabPane>*/}
            {/*    <TabPane tabId="next">*/}
            {/*        <GoalTable openGoalDetail={openGoalDetail} goals={next} />*/}
            {/*    </TabPane>*/}
            {/*</TabContent>*/}

            <div>
                <GoalTable openGoalDetail={openGoalDetail} goals={active} />
            </div>
            <Modal size="xl" isOpen={showGoalDetail} toggle={closeGoalDetail}>
                <ModalHeader toggle={closeGoalDetail}>
                    Goal: {selectedGoal?.name}
                </ModalHeader>
                <ModalBody>
                    <GoalDetails
                        openGoalDetail={openGoalDetail}
                        selected={selectedGoal}
                        dependents={dependents}
                        dependencies={dependencies}
                    />
                </ModalBody>
            </Modal>
        </div>
    ) : (
        <div>Missing rid</div>
    );
}

interface Props {
    goals: ParaflowGoalRow[];
    openGoalDetail: (goal: ParaflowGoalRow) => void;
}

function timeSince(timestamp: number) {
    const now = Date.now();
    const diffSeconds = Math.floor((now - timestamp) / 1000);
    if (diffSeconds < 60) return `${diffSeconds} second(s) ago`;
    const diffMinutes = Math.floor(diffSeconds / 60);
    if (diffMinutes < 60) return `${diffMinutes} minute(s) ago`;
    const diffHours = Math.floor(diffMinutes / 60);
    if (diffHours < 24) return `${diffHours} hour(s) ago`;
    const diffDays = Math.floor(diffHours / 24);
    return `${diffDays} day(s) ago`;
}

function GoalTable({ goals, openGoalDetail }: Props) {
    const ages = goals.map((goal) => new Date(goal.meta.updated).getTime());
    return goals.length > 0 ? (
        <table className="table">
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Age</th>
                </tr>
            </thead>
            <tbody>
                {goals.map((goal, idx) => {
                    return (
                        <tr
                            key={idx}
                            style={{ cursor: "pointer" }}
                            onClick={() => openGoalDetail(goal)}
                        >
                            <td width="10%">{goal.meta.description}</td>
                            <td width="15%">{timeSince(ages[idx])}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    ) : (
        <div>Empty</div>
    );
}

interface SelectedProps {
    selected: ParaflowGoalRow | null;
    dependencies: Map<string, ParaflowGoalRow[]>;
    dependents: Map<string, ParaflowGoalRow[]>;
    openGoalDetail: (goal: ParaflowGoalRow) => void;
}

function GoalDetails({
    selected,
    dependencies,
    dependents,
    openGoalDetail,
}: SelectedProps) {
    const next = dependents.get(selected?.uid || "") || [];
    const prev = dependencies.get(selected?.uid || "") || [];

    return (
        <div>
            <b>Dependencies</b>
            <GoalTable goals={prev} openGoalDetail={openGoalDetail} />
            <b>Dependents</b>
            <GoalTable goals={next} openGoalDetail={openGoalDetail} />
        </div>
    );
}
