import { RJSFSchema } from "@rjsf/utils";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState, MouseEvent } from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Collapse,
    ListGroup,
    ListGroupItem,
    Row,
} from "reactstrap";
import { getParanetClient, getParanetApolloClient, schemadb } from "./index";
import { gql } from "@apollo/client";
// import DataInput from "./DataInput";
import { SchemaRef } from "./Schemas";
import { SkillDialog, SkillRequestForm } from "./SkillRequestForm";

export const GET_SKILLSET = gql`
    query SkillSetDetails($id: EntityId!) {
        skillSetDetails(entityId: $id) {
            entityId
            skills {
                subject
                actions {
                    action
                    inputRef
                    outputRef
                }
            }
        }
    }
`;

export interface ActorSkillsModel {
    skills: SubjectModel[];
}
export interface SkillsetModel {
    entityId: string;
    skills: SubjectModel[];
    baseSkill: boolean;
}

export interface SubjectModel {
    subject: string;
    actions: ActionModel[];
}

export interface ActionModel {
    action: string;
    inputRef?: SchemaRef;
    outputRef?: SchemaRef;
}

export type SkillsetData = {
    id: string;
    skills: SkillsetSubject[];
    baseSkill: boolean;
};

export type SkillsetSubject = {
    subject: string;
    actions: SkillsetAction[];
};

export type SkillsetAction = {
    action: string;
    inputRef?: SchemaRef;
    outputRef?: SchemaRef;
    inputSchema?: RJSFSchema;
    outputSchema?: RJSFSchema;
};

export async function skillsetFromModel(model: SkillsetModel) {
    var skills = [];
    for (const skillModel of model.skills) {
        var actions = [];
        for (const actionModel of skillModel.actions) {
            const { action, inputRef, outputRef } = actionModel;
            const inputSchema =
                inputRef && (await schemadb.schemaProperties(inputRef));
            const outputSchema =
                outputRef && (await schemadb.schemaProperties(outputRef));
            actions.push({
                action,
                inputRef,
                inputSchema,
                outputRef,
                outputSchema,
            });
        }
        skills.push({
            subject: skillModel.subject,
            actions,
        });
    }
    return {
        id: model.entityId,
        baseSkill: model.baseSkill,
        skills,
    };
}

interface Props {
    skills: SkillsetSubject[];
    request_dialog: (subj: SkillsetSubject, action: number) => void;
}

export const SkillsetActions: React.FC<Props> = ({
    skills,
    request_dialog,
}: Props) => {
    const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});
    const toggle = (index: string) => {
        console.log("TOGGLE", index);
        setIsOpen((isOpen) => ({ ...isOpen, [index]: !isOpen[index] }));
    };

    const handleRequestButton = (
        evt: MouseEvent,
        subj: SkillsetSubject,
        action: number
    ) => {
        console.log("HANDLE");
        evt.stopPropagation();
        request_dialog(subj, action);
    };

    console.log("SKILLS", skills);
    return (
        <>
            {skills.map((skill, index) => (
                <Card key={index}>
                    <Button
                        color="link"
                        onClick={() => toggle(index.toString())}
                    >
                        Subject: {skill.subject}
                    </Button>
                    <Collapse isOpen={isOpen[index]}>
                        {skill.actions.map((action, index) => (
                            <Card key={index}>
                                <Button
                                    color="link"
                                    onClick={() => toggle(`${index}-subject`)}
                                >
                                    Action: {action.action}
                                </Button>
                                <Collapse isOpen={isOpen[`${index}-subject`]}>
                                    <Card key={index}>
                                        <CardBody>
                                            <SchemaRender
                                                title={"Input"}
                                                schemaRef={action.inputRef}
                                                schema={action.inputSchema}
                                            />
                                            <SchemaRender
                                                title={"Output"}
                                                schemaRef={action.outputRef}
                                                schema={action.outputSchema}
                                            />
                                            <Row>
                                                <Col md="auto">
                                                    <Button
                                                        color="primary"
                                                        onClick={(e) =>
                                                            handleRequestButton(
                                                                e,
                                                                skill,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        New
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </Card>
                        ))}
                    </Collapse>
                </Card>
            ))}
        </>
    );
};

function SchemaRender({
    title,
    schemaRef,
    schema,
}: {
    title: string;
    schemaRef: any;
    schema: any;
}) {
    console.log("SCHEMA REF", schemaRef);
    // TODO: Render the schema here as JSON.
    if (schemaRef === undefined || schemaRef === null) {
        return null;
    }

    return <Card>{title}:</Card>;
}

function SchemaRefRender({ value, schema }: { value: string; schema: any }) {
    // TODO !!!
    return null;
}

export default function Skillset() {
    const { id } = useParams();
    const [model, setModel] = useState<SkillsetData | null>(null);
    const [providers, setProviders] = useState<string[]>([]);
    const [showActorModal, setShowActorModal] = useState(false);
    const [actorDialog, setActorDialog] = useState<SkillDialog | null>(null);

    useEffect(() => {
        (async () => {
            let skillSetData = await getParanetApolloClient().query({
                query: GET_SKILLSET,
                variables: { id: id },
            });
            let skillset = await skillsetFromModel(
                skillSetData.data.skillSetDetails
            );
            setModel(skillset);
            let client = getParanetClient();
            let providerData = await client.getSkillProviders(id!);
            console.log("PROVIDERS", providerData);
            setProviders(providerData);
        })();
    }, [id]);

    if (!model) {
        return <div>Loading...</div>;
    }

    const openRequest = (subj: SkillsetSubject, actionIdx: number) => {
        const action = subj.actions[actionIdx];
        setActorDialog({
            subject: subj.subject,
            action: action.action,
            schema: action.inputSchema,
        });
        setShowActorModal(true);
    };

    return (
        <Card>
            <CardBody>
                <CardTitle tag="h5">Skillset: {id}</CardTitle>
                <SkillsetActions
                    skills={model.skills}
                    request_dialog={openRequest}
                />
                <ListGroup>
                    <h6 className="mt-3">Providers</h6>
                    {providers.map((provider, index) => (
                        <ListGroupItem key={index}>
                            <Link to={`/actor/${provider}`}>{provider}</Link>
                        </ListGroupItem>
                    ))}
                </ListGroup>
                {actorDialog && (
                    <SkillRequestForm
                        shown={showActorModal}
                        onHide={() => setShowActorModal(false)}
                        skill={actorDialog}
                    ></SkillRequestForm>
                )}
            </CardBody>
        </Card>
    );
}
