import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DataGrid from "react-data-grid";
import { Card, CardBody, CardTitle } from "reactstrap";

export interface TableModel {
    name: string;
    url: string;
    columns: { [key: string]: string };
}

export default function DataTable() {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    const [rows, setRows] = useState([]);

    const table = data.table;
    useEffect(() => {
        if (!data) {
            navigate("/actors");
        }
    }, [data, navigate]);

    useEffect(() => {
        (async () => {
            const data = {
                name: table.name,
                columns: Object.values(table.columns),
            };
            console.log("FETCHING", data, "FROM", table.url);
            const request = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            await fetch(table.url, request)
                .then((response) => response.json())
                .then((data) => {
                    console.log("TABLE DATA", data);
                    setRows(data);
                })
                .catch((error) => console.error(error));
        })();
    }, []);

    if (!data) {
        return null; // or return a loading indicator
    }

    let columns = Object.entries(table.columns).map(([name, key]) => ({
        key,
        name,
        resizable: true,
        sortable: true,
    }));

    return (
        <Card>
            <CardBody>
                <CardTitle tag="h5">Table: {table.name}</CardTitle>
                {/*<CardSubtitle tag="h6" className="mb-2 text-muted">ID: {model.id}</CardSubtitle>*/}
                <DataGrid
                    className="rdg-light"
                    // @ts-ignore
                    columns={columns}
                    rows={rows}
                />
            </CardBody>
        </Card>
    );
}

