import {Link} from "react-router-dom";
import React from "react";

interface Props {
    id: string;
}
export const ActorLink: React.FC<Props> = ({id}) => {
    return (<Link to={`/actor/${id}`}>{id}</Link>)
}

export const ConversationLink: React.FC<Props> = ({id}) => {
    return (<Link to={`/conversation/${id}`}>{id}</Link>)
}

export const SkillSetLink: React.FC<Props> = ({id}) => {
    return (<Link to={`/skillset/${id}`}>{id}</Link>)
}

