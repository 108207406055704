import * as dagre from "@dagrejs/dagre";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionGroup,
    Card,
    Typography,
} from "@mui/joy";
import { memo, useEffect } from "react";
import ReactFlow, {
    Handle,
    Edge,
    isEdge,
    isNode,
    Node,
    Position,
    MarkerType,
    Background,
    MiniMap,
    useNodesState,
    useEdgesState,
} from "reactflow";
import "reactflow/dist/style.css";
import {
    loadConvData,
    Conversation,
    ActorDetails,
} from "../ConversationDetail";
import { Link } from "react-router-dom";
import KvTable from "../KVTable";
import DataRender from "../DataRender";

declare type Element = Node | Edge;

function NodeDataRender({ data }: { data: Conversation }) {
    let outputData = [
        {
            key: "ID",
            value: <Link to={`/conversation/${data.id}`}>{data.id}</Link>,
        },
        {
            key: "Time Created",
            value: data.createdAt.toUTCString(),
        },
    ];

    data.messages.forEach((msg) => {
        let value: any;
        let key: string;
        switch (msg.contents.type) {
            case "SkillRequest":
                key = "Request";
                value = msg.contents.data.data?.data;
                break;
            case "PncpMessage":
                // TODO: Consider special casing "complete" messages here.
                key = msg.contents.data.message.message_type;
                value = msg.contents.data.message.data;
                break;
        }

        outputData.push({
            key,
            value: <DataRender data={value} />,
        });
    });
    return (
        <div>
            <KvTable size="sm" data={outputData} />
        </div>
    );
}

interface ConversationNodeData {
    conversation: Conversation;
}

const ConversationNode = memo(
    ({
        data,
        isConnectable,
    }: {
        data: ConversationNodeData;
        isConnectable: boolean;
    }) => {
        let { conversation } = data;
        console.log("ConversationNode:", data);
        let borderColor = "black";
        switch (conversation.state) {
            case "COMPLETED":
                borderColor = "green";
                break;
            case "ERRORED":
                borderColor = "red";
                break;
            case "RESPONSE_RECEIVED":
                borderColor = "yellow";
                break;
            default:
                break;
        }
        return (
            <Card
                sx={{
                    borderColor: borderColor,
                }}
            >
                <Handle
                    type="target"
                    position={Position.Left}
                    id="pred"
                    isConnectable={isConnectable}
                />
                <Typography>
                    <Link to={`/conversation/${conversation.id}`}>
                        {conversation.id.slice(0, 6)}...
                    </Link>
                </Typography>
                <AccordionGroup sx={{ maxWidth: 400 }}>
                    <Accordion>
                        <AccordionSummary>Context</AccordionSummary>
                        <AccordionDetails>
                            <NodeDataRender data={conversation} />
                        </AccordionDetails>
                    </Accordion>
                </AccordionGroup>
                <Handle
                    type="source"
                    position={Position.Right}
                    id="chld"
                    isConnectable={isConnectable}
                />
            </Card>
        );
    }
);

interface ActorNodeData {
    id: string;
    // Both of these can be true, if the actor both makes a request and issues a follow on request.
    isInitiator: boolean;
    isRecipient: boolean;
    // TODO: Maybe this should be somewhere else.
    // Data sent with the request.
    data: any;
    actorDetails: ActorDetails;
}

const ActorNode = memo(({ data }: { data: ActorNodeData }) => {
    let backgroundColor = "lightblue";
    switch (data.actorDetails.modelKind) {
        case "user":
            backgroundColor = "lightsalmon";
            break;
        case "agent":
            backgroundColor = "lightblue";
            break;
        case "domain":
            backgroundColor = "orange";
            break; // TODO
    }

    let name =
        data.actorDetails.metadata.name == data.actorDetails.entityId ? null : (
            <p>{data.actorDetails.metadata.name}</p>
        );
    return (
        <Card
            style={{
                backgroundColor: backgroundColor,
            }}
        >
            {data.isRecipient ? (
                <Handle type="target" position={Position.Left} id="pred" />
            ) : null}
            {name}
            <Link to={`/actor/${data.id}`}>{data.actorDetails.entityId}</Link>
            {data.isInitiator ? (
                <Handle type="source" position={Position.Right} id="chld" />
            ) : null}
        </Card>
    );
});

const nodeTypes = {
    actor: ActorNode,
    conversation: ConversationNode,
};

const nodeWidth = 285;
const nodeHeight = 85;

const getLayoutedElements = (elements: Element[], direction = "TB") => {
    console.log("GETLAYOUT");
    const isHorizontal = direction === "LR";
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: direction });

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    elements.forEach((el) => {
        if (isNode(el)) {
            dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
        } else {
            // animated edges are dependencies and are not included for layout
            dagreGraph.setEdge(el.source, el.target);
        }
    });

    dagre.layout(dagreGraph, {});

    return elements.map((el) => {
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);
            el.targetPosition = isHorizontal ? Position.Left : Position.Top;
            el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

            // unfortunately we need this little hack to pass a slightly different position
            // to notify react flow about the change. Moreover we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            el.position = {
                x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        }

        return el;
    });
};

interface DataNode {
    conversation: Conversation;
    children: DataEdge[];
}

interface DataEdge {
    id: string;
    linkType: LinkType;
    // A currently unload edge will have a null value here.
    node: DataNode | null;
}

type LinkType = "child" | "observer";

interface Props {
    root: Conversation;
    loader?: (id: string) => Promise<Conversation>;
}

function createElementsFromConversation(
    elements: Element[],
    conversation: Conversation,
    index: number | null
) {
    // This creates the conversation node.
    let convNode: Node<ConversationNodeData> = {
        data: { conversation },
        id: conversation.id,
        position: { x: 0, y: 0 }, // Filled in later.
        type: "conversation",
        draggable: true,
        deletable: false,
    };

    // let initId = `${conversation.id}/${conversation.initiator.actorId}`;
    let initId = conversation.initiator.entityId;
    // Now we create the two actor nodes on either side. It is possible that one of them doesn't exist.
    // We will need to handle that here, but we don't currently. Hence: TODO
    let initNode: Node<ActorNodeData> = {
        id: initId,
        data: {
            data: null,
            id: conversation.initiator.entityId,
            isInitiator: true,
            isRecipient: index != null,
            actorDetails: conversation.initiator,
        },
        position: { x: 0, y: 0 },
        type: "actor",
        draggable: true,
        deletable: false,
    };

    let label = `${conversation.subject}/${conversation.action}`;
    let inLabel = index != null ? `${label} (${index})` : label;
    // Create the edge between the nodes.
    let inEdge: Edge = {
        id: `${conversation.id}-in`,
        source: initId,
        target: conversation.id,
        label: inLabel,
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
        deletable: false,
    };

    elements.push(convNode, initNode, inEdge);

    if (conversation.recipient) {
        let recipId = conversation.recipient.entityId;
        let outNode: Node<ActorNodeData> = {
            type: "actor",
            id: recipId,
            data: {
                data: null,
                id: recipId,
                isInitiator: conversation.childrenIds.length > 0,
                isRecipient: true,
                actorDetails: conversation.recipient,
            },
            position: { x: 0, y: 0 },
            draggable: true,
            deletable: false,
        };

        let outEdge: Edge = {
            id: `${conversation.id}-out`,
            source: conversation.id,
            target: recipId,
            label,
            markerEnd: {
                type: MarkerType.ArrowClosed,
            },
            deletable: false,
        };

        elements.push(outEdge, outNode);
    }

    // Produce a node and edge for every observer as well.
    for (let i = 0; i < conversation.observers.length; ++i) {
        let observer = conversation.observers[i];
        let obsNode: Node<ActorNodeData> = {
            id: observer.targetActor.entityId,
            type: "actor",
            position: { x: 0, y: 0 },
            data: {
                data: null,
                id: observer.targetActor.entityId,
                // TODO: This _can_ be an initiator if another conversation was started after this.
                // In general we need a better way of handling this: either we should create a better
                // API for querying all of this data in a linked format in the first place, or we need
                // to create a map of nodes and merge the information in them together instead of just
                // pushing them into an array up front.
                isInitiator: false,
                isRecipient: true,
                actorDetails: observer.targetActor,
            },
            deletable: false,
        };
        // Create the observer edge, which is special compared to others.
        let obsEdge: Edge = {
            id: observer.obsId,
            source: conversation.id,
            target: observer.targetActor.entityId,
            markerEnd: {
                type: MarkerType.ArrowClosed,
            },
            // Makes a dotted line instead.
            // TODO: Might be a good idea to create a separate style sheet and add the class.
            style: {
                stroke: "#bbb",
                strokeDasharray: "5 5",
            },
            deletable: false,
            // Cool, but kills performance.
            // animated: true,
        };
        elements.push(obsNode, obsEdge);
    }
}

async function fetchConversationState(
    id: string,
    index: number | null,
    elements: Element[]
): Promise<Conversation> {
    let conv = await loadConvData(id);
    createElementsFromConversation(elements, conv, index);
    // Now, create the edges, then recursively create their nodes.
    for (let i = 0; i < conv.childrenIds.length; ++i) {
        let childId = conv.childrenIds[i];
        await fetchConversationState(childId, i, elements);
    }

    return conv;
}

export function Graph({ root, loader }: Props) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    useEffect(() => {
        (async () => {
            let elements: Element[] = [];
            // NOTE: This duplicates fetching the first entry, but that is probably fine...
            await fetchConversationState(root.id, null, elements);
            let layoutedElements = getLayoutedElements(elements, "LR");
            const nodes: Node[] = layoutedElements
                .filter((e) => isNode(e))
                .map((e) => e as Node);
            console.log("Nodes:", nodes);
            const edges: Edge[] = layoutedElements
                .filter((e) => isEdge(e))
                .map((e) => e as Edge);
            console.log("Edges :", edges);
            setNodes(nodes);
            setEdges(edges);
        })();
    }, [root.id]);

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            // defaultZoom={1.5}
            // Disable dragging new edges between nodes.
            nodesConnectable={false}
            // elementsSelectable={false}
            // onPaneClick={onPaneClick}
        >
            <MiniMap />
            <Background />
        </ReactFlow>
    );
}
