import { useParams } from "react-router-dom";
import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { getParanetApolloClient } from "./index";
import { SkillsetData, SkillsetModel, skillsetFromModel } from "./Skillset";
import {
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import ActorSkillsets from "./ActorSkillsets";

export const GET_ACTOR = gql`
    query ListActors($id: String) {
        listActors(id: $id) {
            base {
                id
                name
                kind
                model
            }
            actors {
                entityId
                skillSets {
                    entityId
                    skills {
                        subject
                        actions {
                            action
                            inputRef
                            outputRef
                        }
                    }
                    baseSkill
                }
            }
        }
    }
`;

interface ListActors {
    base: BaseActor;
    actors: QActorDetail[];
}

interface BaseActor {
    id: string;
    name: string;
    kind: string;
    model: any;
}

interface QActorDetail {
    entityId: string;
    skillSets: SkillsetModel[];
}

interface ActorDetail {
    entityId: string;
    skillSets: SkillsetData[];
}

export default function Actor() {
    let { id } = useParams();
    id = id?.split("@")[0];
    const [model, setModel] = useState<BaseActor | null>(null);
    const [actors, setActors] = useState<ActorDetail[]>([]);

    useEffect(() => {
        (async () => {
            let data = await getParanetApolloClient().query<{
                listActors: ListActors;
            }>({
                query: GET_ACTOR,
                variables: { id: id },
            });
            console.log("DATA:", data);
            setModel(data.data.listActors.base);
            let acts = [];
            for (const actor of data.data.listActors.actors) {
                let skillSets = [];
                for (const model of actor.skillSets) {
                    skillSets.push(await skillsetFromModel(model));
                }
                acts.push({ entityId: actor.entityId, skillSets });
            }
            setActors(acts);
        })();
    }, [id]);

    if (!model) {
        return <div>Loading...</div>;
    } else if (model.kind === "user") {
        console.log("USER", model);
        return <User model={model} impls={actors} />;
    } else if (model.kind === "agent") {
        console.log("AGENT", model);
        return <Agent model={model} impls={actors} />;
    } else {
        console.log("UNKNOWN", model);
        return <div>Unknown</div>;
    }
}

interface Props {
    model: BaseActor;
    impls: ActorDetail[];
}

function Agent({ model, impls }: Props) {
    return (
        <Card>
            <CardBody>
                <BaseActorHeader model={model} />
                {/*
                <ListGroup>
                    <h6 className="mt-3">Tables</h6>
                    {model.tables.map((table, index) => (
                        <ListGroupItem key={index}>
                            <Link
                                to={`/actor/${model.id}/table/${table.name}`}
                                state={{ table: table }}
                            >
                                {table.name}
                            </Link>
                        </ListGroupItem>
                    ))}
                </ListGroup>
                */}

                <ActorImpls impls={impls} />
            </CardBody>
        </Card>
    );
}

function User({ model, impls }: Props) {
    return (
        <Card>
            <CardBody>
                <BaseActorHeader model={model} />
                <ActorImpls impls={impls} />
            </CardBody>
        </Card>
    );
}

function BaseActorHeader({ model }: { model: BaseActor }) {
    return (
        <>
            <CardTitle tag="h5">User: {model.name}</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted">
                ID: {model.id}
            </CardSubtitle>
        </>
    );
}

function ActorImpls({ impls }: { impls: ActorDetail[] }) {
    return (
        <ListGroup>
            <h6 className="mt-3">Implementations</h6>
            {impls.map((impl) => (
                <ListGroupItem key={impl.entityId}>
                    <ActorImpl impl={impl} />
                </ListGroupItem>
            ))}
        </ListGroup>
    );
}

function ActorImpl({ impl }: { impl: ActorDetail }) {
    // <ActorSkillsets skillsets={model.skillsets} />
    return (
        <div>
            <CardSubtitle tag="h6">{impl.entityId}</CardSubtitle>
            <ActorSkillsets skillsets={impl.skillSets} />
        </div>
    );
}
