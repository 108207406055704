import { useState } from "react";
// TODO: Come up with a cleaner way of invalidating tokens and such on new versions.
const AUTH_TOKEN = "paranet_token-v1.8";

export interface ActorContext {
    // Actually the actor ID, not the name.
    name: string;
    versions: string[];
    selectedVersion: number;
    token: string;
    refresh: string;
}

export function getActorContext(): ActorContext | null {
    const string = localStorage.getItem(AUTH_TOKEN);
    if (string == null) return null;
    return JSON.parse(string);
}

export function setActorContext(ctx: ActorContext) {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(ctx));
}

export default function useActorContext() {
    const [actorContext, setActorContext] = useState<ActorContext | null>(
        getActorContext()
    );

    const saveActorContext = (actorContext: ActorContext | null) => {
        console.log("Storing", actorContext);
        localStorage.setItem(AUTH_TOKEN, JSON.stringify(actorContext));
        setActorContext(actorContext);
    };

    return {
        setActorContext: saveActorContext,
        actorContext,
    };
}
