var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Wrapper around ports and events to have async/await semantics on messages that expect a response.
export class Channel {
    constructor(port, incoming) {
        this.evs = new Map();
        this.port = port;
        this.evId = 0;
        this.port.onMessage.addListener((msg) => {
            let id = msg.id;
            // TODO Reconsider these semantics.
            if (id === null || id === undefined) {
                incoming(msg, this);
            }
            else {
                let cb = this.evs.get(id);
                this.evs.delete(id);
                if (cb) {
                    cb(msg.message);
                }
            }
        });
    }
    send(msg, cb) {
        var id;
        if (cb === undefined) {
            id = null;
        }
        else {
            id = this.evId++;
            this.evs.set(id, cb);
        }
        // Construct the input message and post it.
        let message = { message: msg, id };
        this.port.postMessage(message);
    }
    aSend(msg) {
        return __awaiter(this, void 0, void 0, function* () {
            let p = new Promise((r) => this.send(msg, (m) => r(m)));
            return yield p;
        });
    }
}
// Handler function for port listeners on the other end of the channel.
// Could make this extend message body as a requirement, but not sure that is necessary.
export function portHandler(port, cb) {
    port.onMessage.addListener((msg, p) => __awaiter(this, void 0, void 0, function* () {
        console.log("port:", port.name, "received message:", msg);
        let r = yield cb(msg.message, p);
        console.log("port message handler response: ", port.name, r);
        let id = msg.id;
        if (id !== null) {
            p.postMessage({ message: r, id: msg.id });
        }
        else {
            p.postMessage(r);
        }
    }));
}
